import * as echarts from 'echarts'

function Pie() {
    let dataArr = [];
    for (let i = 0; i < 150; i++) {
        if (i % 2 === 0) {
            dataArr.push({
                name: (i + 1).toString(),
                value: 50,
                itemStyle: {
                    color: '#00AFFF',
                    borderWidth: 0,
                    borderColor: 'rgba(0,0,0,0)'
                }
            });
        } else {
            dataArr.push({
                name: (i + 1).toString(),
                value: 100,
                itemStyle: {
                    color: 'rgba(0,0,0,0)',
                    borderWidth: 0,
                    borderColor: 'rgba(0,0,0,0)'
                }
            });
        }
    }
    return dataArr;
}


export function  getMothWarn(echartData, rich,scale){
    const CarInOutOption = {
        // grid: {left: '25%',right: '12%',top: '2%',bottom: '20%',},
    legend: {
        selectedMode:false,
        formatter: function(name) {
            var total = 0; //各科正确率总和
            var averagePercent; //综合正确率
            echartData.forEach(function(value, index, array) {
                total += value.value;
            });
            return '{total|' + total + '}';
        },
        data: [echartData[0].name],
        // data: ['高等教育学'],
        // itemGap: 50,
        left: 'center',
        top: 'center',
        icon: 'none',
        align:'center',
        textStyle: {
            color: "#fff",
            fontSize: 8 * scale,
            rich: rich
        },
    },
    
    series: [{
        name: '总考生数量',
        type: 'pie',
        radius: ['38%', '50%'],
        hoverAnimation: false,
        color: ['#88e6bc', '#0e60f6',],
        center: ['center', '50%'], // 调整饼图的位置
        splitNumber: 35,
        label: {
            normal: {
                formatter: function(params, ticket, callback) {
                    var total = 0; //考生总数量
                    var percent = 0; //考生占比
                    echartData.forEach(function(value, index, array) {
                        total += value.value;
                    });
                    percent = ((params.value / total) * 100).toFixed(1);
                    return '{white|' + params.name + '}\n{hr|}\n{yellow|' + params.value + '}';
                },
                rich: rich
            },
        },
        
        labelLine: {
            normal: {
                length: 20 * scale,
                length2: 20 * scale,
                lineStyle: {
                    color: '#81E9BF',
                   
                  
                },
                
                position: function(params) {
                    console.log('params',params);
                    
                    if (params.dataIndex === 0) {
                     
                        return 'top'; // 第一个数据点引导线位置为右上角
                    } else if (params.dataIndex === 1) {
                        return 'bottom'; // 第二个数据点引导线位置为右下角
                    } else {
                        return 'inner'; // 默认位置
                    }
                }
            },
      
        },
        color: [
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#b2faac' }, // 渐变色起始颜色
                { offset: 1, color: '#5af0d0' } // 渐变色结束颜色
            ]),
            new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#05c4f6' }, // Gradient color for the third data point
                { offset: 1, color: '#0f5bf6' } // Gradient color for the fourth data point
            ])
        ],
        data: echartData
    },
    {
        // 短刻度节点线
        type: 'gauge',
        startAngle: 270, // 270
        endAngle: -89.9999,
        center: ['50%', '50%'],
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false
        },
        radius: '86%',
        splitNumber: '42', // 42
        axisLine: {
            show: false,
            lineStyle: {
                color: [
                    [1, '#1755ff'] // 01ADF8 1755ff
                ],
                width: 10
            }
        },
        splitLine: {
            length: 6,
            lineStyle: {
                width: 2,
                color: '#1d4d81',
                distance: 10,
            }
        },
        detail: {
            show: false
        },
    },
]
    }
    return CarInOutOption
}
export function  getFace(echartData, rich,scale){
    const CarInOutOption = {
      
    legend: {
        selectedMode:false,
        formatter: function(name) {
            var total = 0; //各科正确率总和
            var averagePercent; //综合正确率
            echartData.forEach(function(value, index, array) {
                total += value.value;
            });
            return '{total|' + total + '}';
        },
        data: [echartData[0].name],
      
        left: 'center',
        top: 'center',
        icon: 'none',
        align:'center',
        textStyle: {
            color: "#fff",
            fontSize: 12 * scale,
            rich: rich
        },
    },
    series: [{
        name: '总考生数量',
        type: 'pie',
        radius: ['42%', '50%'],
        hoverAnimation: false,
        color: ['#e19b16', '#3d8c4f',],
        label: {
            normal: {
                formatter: function(params, ticket, callback) {
                    var total = 0; 
                    var percent = 0; 
                    echartData.forEach(function(value, index, array) {
                        total += value.value;
                    });
                    percent = ((params.value / total) * 100).toFixed(1);
                    return '{white|' + params.name + '}\n{hr|}\n{yellow|' + params.value + '}\n{blue|' + percent + '%}';
                },
                rich: rich
            },
        },
        labelLine: {
            normal: {
                length: 20 * scale,
                length2: 20 * scale,
                lineStyle: {
                    color: '#0b5263'
                }
            }
        },
        data: echartData
    }]
    }
    return CarInOutOption
}

export function  getViolaTion(chartData,xData,sData,lineOption,bgColor,fontColor){
    const CarInOutOption = {
        title: {
            text: '',
            textStyle: {
                fontSize: 20,
                color: '#fff',
            },
        },
        backgroundColor: bgColor,
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    grid: {
        top: '14%',
        right: '3%',
        left: '5%',
        bottom: '14%'
    },
    xAxis: [{
        type: 'category',
        data: xData,
        axisLine: lineOption,
        axisTick: {
            show: false
        },
        axisLabel: {
            margin: 10,
            color: fontColor,
            textStyle: {
                fontSize: 14
            },
        },
    }],
    yAxis: [{
        axisLabel: {
            formatter: '{value}',
            color: fontColor,
        },
        axisTick: {
            show: false
        },
        axisLine: lineOption,
        splitLine: lineOption
    },{
        axisLine: lineOption,
        axisTick: {
            show: false
        },
        splitLine: {
            show:false
        }
    }],
    series: [{
        type: 'bar',
        data: sData,
        barWidth: '20px',
        itemStyle: {
            normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#29aa76' // 0% 处的颜色
                }, {
                    offset: 1,
                    color: '#092b2c' // 100% 处的颜色
                }], false)
            }
        }
    }]
    }
    return CarInOutOption
}


export function  getToadyWarn(objData,colorList,m,seriesdata1){
    const CarInOutOption = {
        // backgroundColor:"#051F54",
        grid: {
                    "x": "30%",
                    "y": "3%",
                    "x2": "5%",
                    "y2": "5%",
                    "borderWidth": 1,
                    "borderColor": "#f0f0f0"
                },
           
            "legend": {
                "show": true,
                //icon: 'circle',
                orient: 'vertical',
                top: '70%',
                left: '10%',
                itemGap: 15,
                itemWidth:15,
                itemHeight:10,
                data: seriesdata1,
                formatter: function (name) {
                  
                    
                  return '{a|' + name +'}{b|' +objData[name].value.toFixed(0) +'}{c|' + Number(objData[name].data).toLocaleString() +'%}'
                },
                textStyle: {
                  rich: {
                    a: {
                      align: 'center',
                      fontSize: 14,
                      color:'rgba(255,255,255,1)',
                      width:20,
                      //fontWeight: 600,
                      padding: [0, 0, 0, 10]
                    },
                    b: {
                      align: 'center',
                      fontSize: 14,
                      color:'rgba(255,255,255,1)',
                      width:5,
                      //fontWeight: 600,
                      padding: [0, 0, 0, 40]
                    },
                    c: {
                      align: 'center',
                      fontSize: 14,
                      color:'rgba(255,255,255,1)',
                      width:5,
                      //fontWeight: 600,
                      padding: [0, 0, 0, 50]
                    }
                  }
                }
            },
        series: {
            type: 'pie',
            center: ['50%', '30%'],
            radius: ['15%', '30%'],
            clockwise: true,
            //startAngle: 50,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
                normal: {
                    color: function(params) {
                        return colorList[params.dataIndex]
                    }
                }
            },
            label: {
                show: true,
                position: 'outside',
                formatter: function(data){
               
                  return  '\n{icon|}\n' + '{name|' + data.name + "}" +" {value|" + data.value.toFixed(0) + ",  "+ data.percent+"%}"  ;
                                      },
                    //padding: [-40, -90, -20, -80],
                    rich: {
                        icon: {
                            height:5,
                            width:5,
                            lineHeight: 5,
                            //marginBottom: 10,
                            padding:[0,-5],
                            borderRadius:5,
                            backgroundColor:'t', // 圆点颜色和饼图块状颜色一致
                        },
                        name: {
                            fontSize: 14,
                            padding: [20, 100, -20, -90],
                            color: '#ffffff'
                        },
                        value: {
                            fontSize: 14,
                            padding: [20, -90, -20, -100],
                            color: '#ffffff'
                        }
                    }
            },
            
            labelLine: {
                show: false,
                normal: {
                    length: 20,
                    length2: 100,
                    align:'right',
                    lineStyle: {
                        width: 1
                    }
                }
            },
            
                    "data": seriesdata1,
                    "seriesIndex": 0
                }
    }
    return CarInOutOption
}


export function  getStateData(wind,momentratio,height,weight,travel,time){
   
    
    const CarInOutOption = {
        color: ['#00DDFF', '#d54878', '#80FFA5', '#37A2FF', '#FFBF00'],
        title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
        type: 'cross',
        label: {
            backgroundColor: '#6a7985'
        }
    }
  },
  legend: {
    data: ['风速', '力矩比','高度','重量','行程'],
    textStyle:{
        color:'#ffffff',
        
    }
  },
  grid: {
    left: '3%',
   
    right: '4%',
    bottom: '1%',
    containLabel: true
  },
//   toolbox: {
//     feature: {
//       saveAsImage: {}
//     }
//   },
xAxis: {
    type: 'category',
    boundaryGap: false,
    
    axisLine:{
        lineStyle:{
            color:'#316878'
        },
    },
    axisLabel: {
        color: 'white' // Set x-axis label font color to white
    },
    data: time
},
yAxis: {
    type: 'value',
    splitLine: {
        show: true,
        lineStyle: {
            color: '#316878' // 设置分隔线颜色为红色
        }
        
    },
    axisLine:{
        lineStyle:{
            color:'#ffffff'
        },
    },
},
  series: [
    {
      name: '风速',
      type: 'line',
    
      data: wind
    },
    {
      name: '力矩比',
      type: 'line',
    
      data: momentratio
    },
    {
      name: '高度',
      type: 'line',
   
      data: height
    },
    {
      name: '重量',
      type: 'line',
     
      data: weight
    },
    {
      name: '行程',
      type: 'line',
     
      data:travel
    }
  ]
    }
    return CarInOutOption
}


export function  getLoad(dataArr, colorSet,color1,color2,name){
    const CarInOutOption = {
        // backgroundColor: '#0E1327',
        tooltip: {
            formatter: "{a} <br/>{b} : {c}%"
        },

        series: [
            {
                name: "内部进度条",
                type: "gauge",
                // center: ['20%', '50%'],
                radius: '52%',

                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        color: [
                            [dataArr / 100, colorSet.color],
                            [1, colorSet.color]
                        ],
                        width: 2
                    }
                },
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,

                },
                splitLine: {
                    show: false,
                },
                itemStyle: {
                    color:"#ffffff"
                },
                detail: {
                    show: false,
                    formatter: function(value) {
                        if (value !== 0) {
                            var num = Math.round(value ) ;
                        
                            
                            // return parseInt(num).toFixed(0)+"%";
                            return num
                        } else {
                            return 0;
                        }
                    },
                    offsetCenter: [0, 67],
                    textStyle: {
                        padding: [0, 0, 0, 0],
                        fontSize: 18,
                        fontWeight: '700',
                        color: '#ffffff'
                    }
                },
                title: { //标题
                    show: false,
                    offsetCenter: [0, 46], // x, y，单位px
                    textStyle: {
                        color: "rgba(0,0,0,0)",
                        fontSize: 14, //表盘上的标题文字大小
                        fontFamily: 'PingFangSC'
                    }
                },
                data: [{
                    name: "",
                    value: dataArr,
                }],
                pointer: {
                    show: true,
                    length: '70%',
                    radius: '20%',
                    width: 3, //指针粗细
                    value: dataArr 

                },
                animationDuration: 4000,
            },
            {
                name: "内部阴影",
                type: "gauge",
                radius: '44%',
                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        color: [
                            [dataArr / 100, new echarts.graphic.LinearGradient(
                                0, 1, 0, 0, [{
                                        offset: 0,
                                        color: 'rgba(45,230,150,0)',
                                    }, {
                                        offset: 0.5,
                                        color: 'rgba(45,230,150,0.2)',
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(45,230,150,1)',
                                    }
                                ]
                            )],
                            [
                                1, 'rgba(28,128,245,0)'
                            ]
                        ],
                        width: 100

                    },
                },
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,

                },
                splitLine: {
                    show: false,
                },
                itemStyle: {
                    show: false,
                },
           
            },
            {
                name: "内部小圆",
                type: "gauge",
                // center: ['20%', '50%'],
                radius: '46%',

                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        color: [
                            [dataArr / 100, color2],
                            [1, "rgba(0,0,0,0)"]
                        ],
                        width: 10
                    }
                },
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,

                },
                splitLine: {
                    show: false,
                },
                itemStyle: {
                    show: false,
                },
            },
            {
                name: '外部刻度',
                type: 'gauge',
                //  center: ['20%', '50%'],
                radius: '48%',
                min: 0, //最小刻度
                max: 100, //最大刻度
                splitNumber: 5, //刻度数量
                startAngle: 225,
                endAngle: -45,
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 1,
                        color: [
                            [1, 'rgba(0,0,0,0)']
                        ]
                    }
                }, //仪表盘轴线
                axisLabel: {
                    show: true,
                    color: '#ffffff',
                    fontSize:14,
                    fontFamily:'SourceHanSansSC-Regular',
                    fontWeight:'bold',
                    // position: "top",
                    distance: -30,
                    formatter: function(v) {
                        // switch (v + '') {
                        //     case '0':
                        //         return '0';
                        //     case '10':
                        //         return '10';
                        //     case '20':
                        //         return '20';
                        //     case '30':
                        //         return '30';
                        //     case '40':
                        //         return '40';
                        //     case '50':
                        //         return '50';
                        //     case '60':
                        //         return '60';
                        //     case '70':
                        //         return '70';
                        //     case '80':
                        //         return '80';
                        //     case '90':
                        //         return '90';
                        //     case '100':
                        //         return '100';
                        // }
                        return v;
                    }
                }, //刻度标签。
                axisTick: {
                    show: true,
                    splitNumber: 3,
                    lineStyle: {
                        color: color1, //用颜色渐变函数不起作用
                        width: 1,
                    },
                    length: -6
                }, //刻度样式
                splitLine: {
                    show: true,
                    length: -12,
                    lineStyle: {
                        color: color1, //用颜色渐变函数不起作用
                    }
                }, //分隔线样式
                detail: {
                    show: false
                }
            },
            {
                name: "内部进度条",
                type: "gauge",
                // center: ['20%', '50%'],
                radius: '20%',

                splitNumber: 10,
                axisLine: {
                    lineStyle: {
                        color: [
                            [dataArr / 100, colorSet.color],
                            [1, colorSet.color]
                        ],
                        width: 1
                    }
                },
                axisLabel: {
                    show: false,
                },
                axisTick: {
                    show: false,

                },
                splitLine: {
                    show: false,
                },
                itemStyle: {
                    color:"#ffffff"
                },
                detail: {
                    formatter: function(value) {
                        if (value !== 0) {
                            var num = Math.round(value ) ;
                            // return parseInt(num).toFixed(0)+"%";
                        
                            return value;
                        } else {
                            return 0;
                        }
                    },
                    offsetCenter: [0, 67],
                    textStyle: {
                        padding: [0, 0, 0, 0],
                        fontSize: 18,
                        color: "#ffffff"
                    }
                },
                title: { //标题
                    show: true,
                    offsetCenter: [0, 46], // x, y，单位px
                    textStyle: {
                        color: "#fff",
                        fontSize: 14, //表盘上的标题文字大小
                        fontWeight: 400,
                        fontFamily: 'MicrosoftYaHei'
                    }
                },
                data: [{
                    name: name,
                    value: dataArr,
                    itemStyle:{
                        color:"#ffffff",
                        fontFamily: "MicrosoftYaHei",
                        fontSize:14
                    }
                }],
                pointer: {
                    show: true,
                    length: '70%',
                    radius: '20%',
                    width: 3, //指针粗细
                    value: dataArr 

                },
                animationDuration: 4000,
            },
            { //指针上的圆
            type: 'pie',
            tooltip: {
                show: false
            },
            hoverAnimation: false,
            legendHoverLink: false,
            radius: ['0%', '4%'],
            center: ['50%', '50%'],
            label: {
                normal: {
                    show: false
                }
            },
            labelLine: {
                normal: {
                    show: false
                }
            },
            data: [{
                value: 120,
                itemStyle: {
                    normal: {
                        color: "#ffffff",
                    },
                }
            }]
        },
            
        ]
    }
    return CarInOutOption
}

export function  getMaps(date,data,date2,data2){
    const CarInOutOption = {
        title: {
            text: name_title,
            subtext: subname,
            x: 'center',
            textStyle: {
                color: nameColor,
                fontFamily: name_fontFamily,
                fontSize: name_fontSize
            },
            subtextStyle:{
                fontSize:subname_fontSize,
                fontFamily:name_fontFamily
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: function(params) {
                if (typeof(params.value)[2] == "undefined") {
                    var toolTiphtml = ''
                    for(var i = 0;i<toolTipData.length;i++){
                        if(params.name==toolTipData[i].name){
                            toolTiphtml += toolTipData[i].name+':<br>'
                            for(var j = 0;j<toolTipData[i].value.length;j++){
                                toolTiphtml+=toolTipData[i].value[j].name+':'+toolTipData[i].value[j].value+"<br>"
                            }
                        }
                    }
                    console.log(toolTiphtml)
                    // console.log(convertData(data))
                    return toolTiphtml;
                } else {
                    var toolTiphtml = ''
                    for(var i = 0;i<toolTipData.length;i++){
                        if(params.name==toolTipData[i].name){
                            toolTiphtml += toolTipData[i].name+':<br>'
                            for(var j = 0;j<toolTipData[i].value.length;j++){
                                toolTiphtml+=toolTipData[i].value[j].name+':'+toolTipData[i].value[j].value+"<br>"
                            }
                        }
                    }
                    console.log(toolTiphtml)
                    // console.log(convertData(data))
                    return toolTiphtml;
                }
            }
        },
        // legend: {
        //     orient: 'vertical',
        //     y: 'bottom',
        //     x: 'right',
        //     data: ['credit_pm2.5'],
        //     textStyle: {
        //         color: '#fff'
        //     }
        // },
        visualMap: {
            show: true,
            min: 0,
            max: 200,
            left: 'left',
            top: 'bottom',
            text: ['高', '低'], // 文本，默认为数值文本
            calculable: true,
            seriesIndex: [1],
            inRange: {
                // color: ['#3B5077', '#031525'] // 蓝黑
                // color: ['#ffc0cb', '#800080'] // 红紫
                // color: ['#3C3B3F', '#605C3C'] // 黑绿
                // color: ['#0f0c29', '#302b63', '#24243e'] // 黑紫黑
                // color: ['#23074d', '#cc5333'] // 紫红
                color: ['#00467F', '#A5CC82'] // 蓝绿
                // color: ['#1488CC', '#2B32B2'] // 浅蓝
                // color: ['#00467F', '#A5CC82'] // 蓝绿
                // color: ['#00467F', '#A5CC82'] // 蓝绿
                // color: ['#00467F', '#A5CC82'] // 蓝绿
                // color: ['#00467F', '#A5CC82'] // 蓝绿
    
            }
        },
        /*工具按钮组*/
        // toolbox: {
        //     show: true,
        //     orient: 'vertical',
        //     left: 'right',
        //     top: 'center',
        //     feature: {
        //         dataView: {
        //             readOnly: false
        //         },
        //         restore: {},
        //         saveAsImage: {}
        //     }
        // },
        geo: {
            show: true,
            map: mapName,
            label: {
                normal: {
                    show: false
                },
                emphasis: {
                    show: false,
                }
            },
            roam: true,
            itemStyle: {
                normal: {
                    areaColor: '#031525',
                    borderColor: '#3B5077',
                },
                emphasis: {
                    areaColor: '#2B91B7',
                }
            }
        },
        series: [{
                name: '散点',
                type: 'scatter',
                coordinateSystem: 'geo',
                data: convertData(data),
                symbolSize: function(val) {
                    return val[2] / 10;
                },
                label: {
                    normal: {
                        formatter: '{b}',
                        position: 'right',
                        show: true
                    },
                    emphasis: {
                        show: true
                    }
                },
                itemStyle: {
                    normal: {
                        color: '#05C3F9'
                    }
                }
            },
            {
                type: 'map',
                map: mapName,
                geoIndex: 0,
                aspectScale: 0.75, //长宽比
                showLegendSymbol: false, // 存在legend时显示
                label: {
                    normal: {
                        show: true
                    },
                    emphasis: {
                        show: false,
                        textStyle: {
                            color: '#fff'
                        }
                    }
                },
                roam: true,
                itemStyle: {
                    normal: {
                        areaColor: '#031525',
                        borderColor: '#3B5077',
                    },
                    emphasis: {
                        areaColor: '#2B91B7'
                    }
                },
                animation: false,
                data: data
            },
            {
                name: '点',
                type: 'scatter',
                coordinateSystem: 'geo',
                symbol: 'pin', //气泡
                symbolSize: function(val) {
                    var a = (maxSize4Pin - minSize4Pin) / (max - min);
                    var b = minSize4Pin - a * min;
                    b = maxSize4Pin - a * max;
                    return a * val[2] + b;
                },
                label: {
                    normal: {
                        show: true,
                        textStyle: {
                            color: '#fff',
                            fontSize: 9,
                        }
                    }
                },
                itemStyle: {
                    normal: {
                        color: '#F62157', //标志颜色
                    }
                },
                zlevel: 6,
                data: convertData(data),
            },
            {
                name: 'Top 5',
                type: 'effectScatter',
                coordinateSystem: 'geo',
                data: convertData(data.sort(function(a, b) {
                    return b.value - a.value;
                }).slice(0, 5)),
                symbolSize: function(val) {
                    return val[2] / 10;
                },
                showEffectOn: 'render',
                rippleEffect: {
                    brushType: 'stroke'
                },
                hoverAnimation: true,
                label: {
                    normal: {
                        formatter: '{b}',
                        position: 'right',
                        show: true
                    }
                },
                itemStyle: {
                    normal: {
                        color: 'yellow',
                        shadowBlur: 10,
                        shadowColor: 'yellow'
                    }
                },
                zlevel: 1
            },
    
        ]
    }
    return CarInOutOption
}

export function  getWarnData(diff,){
    const CarInOutOption = {
        
        // backgroundColor:"#003366",
        // tooltip: {
        //     show: true,
        //     trigger: 'item',
        //     padding: [8, 15],
        //     backgroundColor: 'rgba(12, 51, 115,0.8)',
        //     borderColor: 'rgba(3, 11, 44, 0.5)',
        //     textStyle: {
        //         color: 'rgba(255, 255, 255, 1)'
        //     },
        // },
        legend: {show: false,},
        grid: {left: '25%',right: '12%',top: '2%',bottom: '12%',},
        xAxis: [
            {
                splitLine: {show: false,},
                type: 'value',
                show: false,
                axisLine: {     //x轴坐标轴，false为隐藏，true为显示
                    show: false
                },
            },
        ],
        yAxis: [{
                show: true,
                splitLine: {show: false,},
                axisLine: { show: false,},
                type: 'category',
                axisTick: {show: false,},
                inverse: true,
                axisLabel: { show: false,},
            },{
                type: 'category',
                inverse: true,
                axisTick: 'none',
                axisLine: 'none',
                show: true,
                axisLabel: {
                    textStyle: {
                        color: '#fff',
                        fontSize: 12
                    },
                    formatter: function (value , index  ) {
                        return value + diff[index].unit ;
                    },
                },
                data: diff,
            },],
        series: [{
                show: true,
                name: '',
                type: 'bar',
                data: diff,
                barWidth: 5, // 柱子宽度
                showBackground: true,
                label: {
                    show: true,
                    width: 200,
                    offset: [0, 0],
                    color: '#fff',
                    fontFamily: 'SourceHanSansCN-Normal',
                    fontSize: 12,
                    fontWeight: 500,
                    position: 'left',
                    formatter: function (params) {
                        return params.data.name;
                    }},
                itemStyle: {
                    show: true,
                    barBorderRadius: [10, 10, 10, 10],
                    color: {
                        type: "linear",
                        colorStops: [{ offset: 0, color: "#2165a7", }, { offset: 1, color: "#36a0d3", },],
                    },
                },
                emphasis: {disabled: true,}
            },
            {
                name: "",
                type: "scatter",
                emphasis: { scale: false, },
                symbol: "rect",
                symbolSize: [3, 9],// 进度条白点
                itemStyle: {
                    show: true,
                    barBorderRadius: [10, 10, 10, 10],
                    color: '#FFF',
                    shadowColor: "rgba(255, 255, 255, 0.5)",
                    shadowBlur: 5,
                    borderWidth: 1,
                    opacity: 1,
                },
                z: 2,
                data: diff,
                animationDelay: 500,
            },
        ],

    }
    return CarInOutOption
}

export function  getProjectTotal(categoryData, chartdata, backgroundColor,itemcolor){
    const CarInOutOption = {
        backgroundColor: backgroundColor,
        textStyle: {
            color: '#c0c3cd',
            fontSize: 14,
        },
        toolbox: {
            show: false,
            feature: {
                saveAsImage: {
                    backgroundColor: '#031245',
                },
                restore: {},
            },
            iconStyle: {
                borderColor: '#c0c3cd',
            },
        },
        legend: {
            top: 10,
            itemWidth: 8,
            itemHeight: 8,
            icon: 'circle',
            left: 'center',
            padding: 0,
            textStyle: {
                color: '#c0c3cd',
                fontSize: 14,
                padding: [2, 0, 0, 0],
            },
        },
        color: ['#00D7E9', 'rgba(0, 215, 233, 0.9)'],
        grid: {
            containLabel: true,
            left: 10,
            right: 20,
            bottom: 10,
            top: 40,
        },
        xAxis: {
            nameTextStyle: {
                color: '#c0c3cd',
                padding: [0, 0, -10, 0],
                fontSize: 14,
            },
            axisLabel: {
                color: '#c0c3cd',
                fontSize: 14,
                interval: 0,
            },
            axisTick: {
                show: false,
                lineStyle: {
                    color: '#384267',
                    width: 1,
                },
            },
            splitLine: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: '#335971',
                },
                show: true,
            },
            data: categoryData,
            type: 'category',
        },
        yAxis: {
            nameTextStyle: {
                color: '#c0c3cd',
                padding: [0, 0, -10, 0],
                fontSize: 14,
            },
            axisLabel: {
                color: '#c0c3cd',
                fontSize: 14,
            },
            axisTick: {
                lineStyle: {
                    color: '#668092',
                    width: 1,
                },
                show: true,
            },
            splitLine: {
                show: false, //刻度线
                lineStyle: {
                    color: '#335971',
                    // "type": "dashed"
                },
            },
            axisLine: {
                lineStyle: {
                    color: '#668092',
                    width: 1,
                    // "type": "dashed"
                },
                show: true,
            },
            name: '',
        },
        series: [
            {
                data: chartdata,
                type: 'bar',
                barMaxWidth: 'auto',
                barWidth: 20,
                itemStyle: {
                    color: {
                        x: 0,
                        y: 1,
                        x2: 0,
                        y2: 0,
                        type: 'linear',
                        colorStops: [
                            {
                                offset: 0,
                                color: '#00D7E9',
                            },
                            {
                                offset: 1,
                                color: 'rgba(0, 167, 233,0.3)',
                            },
                        ],
                    },
                },
                label: {
                    show: true,
                    position: 'top',
                    distance: 10,
                    color: '#fff',
                },
            },
            {
                data: [1, 1, 1, 1, 1, 1],
                type: 'pictorialBar',
                barMaxWidth: '20',
                symbol: 'diamond',
                symbolOffset: [0, '50%'],
                symbolSize: [20, 10],
            },
            {
                data: chartdata,
                type: 'pictorialBar',
                barMaxWidth: '20',
    
                symbolPosition: 'end',
                symbol: 'diamond',
                symbolOffset: [0, '-50%'],
                symbolSize: [20, 12],
                zlevel: 2,
            },
        ],
        tooltip: {
            show: true,
            formatter: '{b}:{c0}',
        },
    }
    return CarInOutOption
}

export function  getLoads(dataArr, colorSet,color1,color2,name){
    const CarInOutOption = {
        series: [
            {
                name: 'test speed',
                type: 'gauge',
                radius: '95%',
                splitNumber: ruler.length - 1, //仪表盘平均分配分段刻度
                max: chartMaxVal,

                axisLine: {
                    lineStyle: {
                        width: 30,
                        color: [
                            [0.5, '#67e0e3'],
                            [0.875, '#37a2da'],
                            [1, '#fd666d'],
                        ],
                    },
                },
                pointer: {
                    itemStyle: {
                        color: 'auto',
                    },
                },
                splitLine: {
                    distance: -30,
                    length: 30,
                    lineStyle: {
                        color: '#fff',
                        width: 4,
                    },
                },
                axisTick: {
                    distance: -30,
                    length: 8,
                    lineStyle: {
                        color: '#fff',
                        width: 2,
                    },
                },
                axisLabel: {
                    color: 'auto',
                    distance: 40,
                    fontSize: 16,
                    //仪表盘刻度设置
                    formatter: valConvert,
                },
                detail: {
                    fontSize: 14,
                    formatter: valText,
                    offsetCenter: [0, '70%'],
                    valueAnimation: true,
                    color: 'auto',
                },
                data: [
                    {
                        value: gaugeData,
                    },
                ],
            },
        ],
    }
    return CarInOutOption
}
export function  getLiftStateData(wind,speed,height,weight,travel,time){
   
    
    const CarInOutOption = {
        color: ['#00DDFF', '#d54878', '#80FFA5', '#37A2FF', '#FFBF00'],
        title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
        type: 'cross',
        label: {
            backgroundColor: '#6a7985'
        }
    }
  },
  legend: {
    data: ['风速', '速度','高度','重量'],
    textStyle:{
        color:'#ffffff',
        
    }
  },
  grid: {
    left: '3%',
   
    right: '4%',
    bottom: '1%',
    containLabel: true
  },
//   toolbox: {
//     feature: {
//       saveAsImage: {}
//     }
//   },
xAxis: {
    type: 'category',
    boundaryGap: false,
    
    axisLine:{
        lineStyle:{
            color:'#316878'
        },
    },
    axisLabel: {
        color: 'white' // Set x-axis label font color to white
    },
    data: time
},
yAxis: {
    type: 'value',
    splitLine: {
        show: true,
        lineStyle: {
            color: '#316878' // 设置分隔线颜色为红色
        }
        
    },
    axisLine:{
        lineStyle:{
            color:'#ffffff'
        },
    },
},
  series: [
    {
      name: '风速',
      type: 'line',
    
      data: wind
    },
    {
      name: '速度',
      type: 'line',
    
      data: speed
    },
    {
      name: '高度',
      type: 'line',
   
      data: height
    },
    {
      name: '重量',
      type: 'line',
     
      data: weight
    },
   
  ]
    }
    return CarInOutOption
}

export function  getBridgeStateData(wind,travel,height,weight,time){
   
    
    const CarInOutOption = {
        color: ['#00DDFF', '#d54878', '#80FFA5', '#37A2FF', '#FFBF00'],
        title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
        type: 'cross',
        label: {
            backgroundColor: '#6a7985'
        }
    }
  },
  legend: {
    data: ['风速', '行程','高度','重量'],
    textStyle:{
        color:'#ffffff',
        
    }
  },
  grid: {
    left: '3%',
   
    right: '4%',
    bottom: '1%',
    containLabel: true
  },
//   toolbox: {
//     feature: {
//       saveAsImage: {}
//     }
//   },
xAxis: {
    type: 'category',
    boundaryGap: false,
    
    axisLine:{
        lineStyle:{
            color:'#316878'
        },
    },
    axisLabel: {
        color: 'white' // Set x-axis label font color to white
    },
    data: time
},
yAxis: {
    type: 'value',
    splitLine: {
        show: true,
        lineStyle: {
            color: '#316878' // 设置分隔线颜色为红色
        }
        
    },
    axisLine:{
        lineStyle:{
            color:'#ffffff'
        },
    },
},
  series: [
    {
      name: '风速',
      type: 'line',
    
      data: wind
    },
    {
      name: '行程',
      type: 'line',
    
      data: travel
    },
    {
      name: '高度',
      type: 'line',
   
      data: height
    },
    {
      name: '重量',
      type: 'line',
     
      data: weight
    },
   
  ]
    }
    return CarInOutOption
}

export function  getFugitiverStateData(wind,pm10,noise,temp,humidity,time){
   
    
    const CarInOutOption = {
        color: ['#00DDFF', '#d54878', '#80FFA5', '#37A2FF', '#FFBF00'],
        title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
        type: 'cross',
        label: {
            backgroundColor: '#6a7985'
        }
    }
  },
  legend: {
    data: ['风速', 'PM10','噪音','温度','湿度'],
    textStyle:{
        color:'#ffffff',
        
    }
  },
  grid: {
    left: '3%',
   
    right: '4%',
    bottom: '1%',
    containLabel: true
  },
//   toolbox: {
//     feature: {
//       saveAsImage: {}
//     }
//   },
xAxis: {
    type: 'category',
    boundaryGap: false,
    
    axisLine:{
        lineStyle:{
            color:'#316878'
        },
    },
    axisLabel: {
        color: 'white' // Set x-axis label font color to white
    },
    data: time
},
yAxis: {
    type: 'value',
    splitLine: {
        show: true,
        lineStyle: {
            color: '#316878' // 设置分隔线颜色为红色
        }
        
    },
    axisLine:{
        lineStyle:{
            color:'#ffffff'
        },
    },
},
  series: [
    {
      name: '风速',
      type: 'line',
    
      data: wind
    },
    {
      name: 'PM10',
      type: 'line',
    
      data: pm10
    },
    {
      name: '噪音',
      type: 'line',
   
      data: noise
    },
    {
      name: '温度',
      type: 'line',
     
      data: temp
    },
    {
      name: '湿度',
      type: 'line',
     
      data:humidity
    }
  ]
    }
    return CarInOutOption
}











 
